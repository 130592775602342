@import url("https://fonts.googleapis.com/css?family=Bree+Serif");

body {
  margin: 0;
  background-color: #94CED9 !important;
  color: #FFF !important;
  letter-spacing: 1px;
  font-family: "Bree Serif", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #337AB7;
  text-decoration: none;
}