.App {
  height: 100vh;
  position: relative;
  min-height: 600px;
}

header {
  height: 25%;
  position: relative;
}

main {
  height: 75%;
  padding-top: 15px;
}

#logo {
  width: auto;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

main img {
  border-radius: 10px;
  margin-bottom: 10px;
}

main img.headshot {
  margin-top: 15px;
  border: 2px solid white;
  width: auto;
  height: 340px;
  margin-left: auto;
  margin-right: auto;
}

main img.hoverable {
  transform-origin: top;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

main img.hoverable:hover {
  cursor: pointer;
  transform: scale(1.1);
}

main .big-picture {
  position: relative;
  width: 50%;
  margin: 0 auto;
}
  main .big-picture div {
    display: block;
  }

main .biz-card-wrapper {
  width: 320px;
  margin: 0 auto;
}

#disclaimers {
  position: absolute;
  width: 100%;
  height: 40px;
  font-size: 14px;
  bottom: 0;
  text-align: center;
}

@media (max-width: 767px) {
  main img.headshot {
    width: auto;
    height: 250px;
  }
}

@media (max-height: 750px) {
  main img.headshot {
    width: auto;
    height: 215px;
  }
}